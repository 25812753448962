//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

.hide-on-mobile { 
  @media screen and (max-width: 800px) {
             display: none !important; 
      }
}
.margin-on-mobile { 
  @media screen and (max-width: 800px) {
            margin-top:1rem;
      }
}
.resize-on-mobile { 
  @media screen and (max-width: 800px) {
             width: 10px;
            height: 10px;

      }
}


  // Trio de cards. FATURAMENTO GERAL
.card-faturamento-mensal {
    background: #f9f9f9;
    color: #5E5873;
    border-radius: 16px;
    font-weight:100;
    height: 220px;
}
.text-card-faturamento-mensal {
    font-size: 36;
    font-weight: bold;
    }
.title-card-faturamento-mensal {
        font-size: 22px;
        font-weight: 500;
        }
.text-footer-card-faturamento-mensal {
            font-size: 16px;
            font-weight: normal;
            color: #b3b3b3;
            }
.chart-faturamento-anual {
                background: #f9f9f9;
                color: #5E5873;
                border-radius: 16px;
                font-weight:100;
            }
.highlight-text-card{
    color: #299702
}


.input-field::placeholder {
    color: #ff0055;
    background-color: #1bb343;
  }
  
  .input-field:focus {
    border: 1px solid #fc5c7d;
    box-shadow: 0px 0px 5px 0.5px #fc5c7d;
  }
  
  .input-field:focus ~ .form-label {
    color: #fc5c7d;
  }
  
  .input-field:focus::placeholder {
    color: transparent;
  }

.btn-size{
 width: 5px;
}